import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './ApiKeyInput.css';
import {baseUrl, fetchOpenApiSpec} from "./api/schema";


const JsonSchema_file = (props) => {
    let typeFile = '*/*';
    props.schema._list._tail.array.forEach((entry)=>{
        if (entry[0] === 'contentMediaType') {
            typeFile = entry[1];
        }
    })
    return (
        <input
            type='file'
            onChange={e => {
                // Extract the file from the event target
                const file = e.target.files[0];
                // Pass the file to the onChange function
                props.onChange(file);
            }}
            accept={typeFile}
        />
    )
}


const SwaggerComponent = () => {
    const {username, projectName} = useParams();
    const [spec, setSpec] = useState(null);
    const [apiKey, setApiKey] = useState('');
    const [error, setError] = useState('');
    const apiKeyInputRef = useRef(null);

    const fetchSpec = async () => {
        setError('');
        try {
            const specData = await fetchOpenApiSpec(username, projectName, apiKey);
            setSpec(specData);
        } catch (err) {
            setError('Invalid API key');
        }
    };

    const handleApiKeySubmit = async () => {
        await fetchSpec();
    };

    useEffect(() => {
        if (apiKeyInputRef.current) {
            apiKeyInputRef.current.focus();
        }
    }, []);

    return (
        <div>
            {!spec ? (
                <div className="swagger-auth-container">
                    <div className="swagger-auth-content">
                        <h1 className="swagger-auth-title">{projectName} Swagger</h1>
                        <input
                            ref={apiKeyInputRef}
                            type="password"
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                            placeholder="Enter API Key"
                            className="swagger-auth-input"
                        />
                        <button onClick={handleApiKeySubmit} className="swagger-auth-button">Verify</button>
                        {error && <div className="swagger-auth-error">{error}</div>}
                    </div>
                </div>
            ) : (
                <SwaggerUI
                    spec={spec}
                    onComplete={ui => {
                        if (apiKey) {
                            ui.preauthorizeApiKey('ApiKeyAuth', apiKey);
                        }
                    }}
                    requestInterceptor={(req) => {
                        req.url = baseUrl + req.url.substring(req.url.indexOf("/api/v1"));
                        return req;
                    }}
                    plugins={[{
                       components: {JsonSchema_file: JsonSchema_file}
                    }]}
                    defaultModelsExpandDepth={-1}
                />
            )}
        </div>
    );
};

export default SwaggerComponent;
