import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SwaggerComponent from './SwaggerComponent';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/api/v1/:username/:projectName/docs" element={<SwaggerComponent/>}/>
            </Routes>
        </Router>
    );
};

export default App;