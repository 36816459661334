export const baseUrl = process.env.REACT_APP_GATEWAY_URL
console.log("baseUrl", baseUrl);


export const fetchOpenApiSpec = async (username, projectName, apiKey) => {
    const url = `${baseUrl}/api/v1/${username}/${projectName}/openapi.json`;
    const headers = apiKey ? { 'X-API-KEY': apiKey } : {};

    try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Error loading the OpenAPI spec:", error);
        throw error;
    }
};